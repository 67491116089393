<template>
	<div class="row">
		<div class="col-12 col-xl-8">
			<!--    TODO: Extract to standalone component on api availability-->
			<form>
				<input
					type="file"
					accept="image/*"
					ref="fileInput"
					class="d-none"
					@change="prepareFileUpload"
					/>
				<div class="form-group">
					<div class="card">
						<div class="card-header d-flex justify-content-end">
							<label class="form-label mb-1">Upload File</label>
							<div>
								<button
									@click="handleClearing"
									class="btn mx-2 btn-sm btn-outline-primary"
									>
									Clear
								</button>
								<button
									@click="handleUpload"
									class="btn mx-2 btn-sm btn-primary"
									>
									Upload
								</button>
							</div>
						</div>
						<div class="card-body">
							<div class="dropzone dz-clickable">
								<div
									class="
                    dz-preview
                    list-group list-group-lg list-group-flush
                    mt-4
                  "
									v-if="form.imageUri"
									>
									<img
										:src="form.imageUri"
										class="img-thumbnail img-fluid mx-auto"
										style="width: 200px; height: 200px"
										alt="Profile Image"
										/>
								</div>

								<button
									@click="openFileSelector"
									class="dz-default dz-message py-5"
									>
									Click to choose file
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="col-12 col-xl-8 mt-5">
			<div class="card">
				<div class="card-header">
					<div class="row align-items-center">
						<h4 class="card-header-title">Uploaded Documents</h4>
					</div>
				</div>
				<app-table
					:fields="documentFields"
					:items="documents"
					:loading="loading"
					:error-loading="errorLoading"
					@retry="fetchDocuments"
					>
					<template v-slot:date="{ item }">
						{{ item.created_at | date('hh:mm a MMM d, yyyy') }}
					</template>
					<template v-slot:name="{ item }">
						{{ item.name }}
					</template>
					<template v-slot:action="{ item }">
						<span>{{ item.name }}</span>
					</template>
				</app-table>
			</div>
		</div>
	</div>
</template>

<script>
import AppTable from '@/components/core/AppTable'
export default {
  name: 'PartnerDocuments',
  components: { AppTable },
  props: {
    partnerId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      allowedMimeTypes: [],
      form: {
        imageUri: null
      },

      errorLoading: false,
      loading: false,
      documentFields: [
        { key: 'name', label: 'File name' },
        { key: 'date', label: 'Date Added' },
        { key: 'action', label: 'Action' }
      ],
      documents: []
    }
  },
  created () {},
  methods: {
    prepareFileUpload (event) {
      const fileReader = new FileReader()

      fileReader.onload = (e) => {
        this.form.imageUri = e.target.result
      }

      fileReader.readAsDataURL(event.target.files[0])
    },
    openFileSelector () {
      this.$refs.fileInput.click()
    },
    fetchDocuments () {},
    handleClearing () {
      this.form.imageUri = null
    },
    handleUpload () {}
  }
}
</script>

<style scoped></style>
